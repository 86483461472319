/* Constants */
import items from './items';

const latestLunarCalendarId: string = items.lunarCalendar2024.id;
const preorderId: string = items.lunarCalendar2025Preorder.id;

const itemsSpecificInfo = {
  latestLunarCalendarId,
  preorderId
};

export default itemsSpecificInfo;
