/* Interfaces */
import LunarPhases from '../interfaces/LunarPhases';
import MoonCloudSVG from '../interfaces/MoonCloudSVG';

const arrowDirectional: string = '41.07 .35 36.12 5.3 68.75 37.93 0 37.93 0 44.93 68.75 44.93 36.12 77.55 41.07 82.5 82.15 41.43 41.07 .35';
const closingX: string = '125.66 76.67 90.42 41.43 125.66 6.19 120.71 1.24 85.47 36.48 50.23 1.24 45.28 6.19 80.52 41.43 45.28 76.66 50.23 81.61 85.47 46.38 120.71 81.62 125.66 76.67';

const moonClouds: MoonCloudSVG[] = [
  {
    name: 'cloud-top',
    pathDrawn: 'm8.96,15.37c1.73-.07,2.26-1.98,3.67-1.91s2.12,1.76,3.53,1.69,1.84-1.41,3.11-1.41,1.91,1.76,3.74,1.76,2.61.49,2.61.92-1.34.64-2.19.64-1.06-.37-2.54-.28c-2.02.12-2.09,1.44-3.18,2.33-.45.37-1.13.91-1.13,1.55,0,1.34,1.86,2.08,3.11,2.05,3.11-.07,2.89-1.98,3.74-1.98s2.4.71,3.88-.64c.78-.7,1.16-2.12,2.71-2.22,4.06-.25,1.38,2.36,4.49,2.36,2.54,0,1.91.78,2.19,1.55.33.9,2.19,1.27,4.8,1.27,2.47,0,2.4-1.2,3.39-1.34s1.41,0,2.19-.35,4.8-.14,4.87-1.55c.09-1.76-2.4-2.19-4.38-2.19s-1.2-2.12-3.11-2.19-4.94,0-4.87-.99,2.4-.21,3.39-.85c1.47-.94,1.27-2.05,3.11-1.98s3.63-.13,4.8.21c1.84.54,2.46,1.88,5.07,1.81s4.53-.61,4.89-1.1,1.23-1.28,1.98-1.34c1.84-.14,2.26-2.05-.03-2.26-.9-.08-2.08-.49-3.28-.56s-.28-1.69-3.04-1.69-2.75,1.87-3.67,1.94-2.68.11-3.6.11-2.61.28-3.46-.21-1.06-1.13-1.69-1.41-1.34.14-1.76-.56.71-1.55,1.91-1.55,3.92-.63,3.95-1.2c.07-1.27-2.47-1.69-3.81-1.62s-3.95.14-4.45,0,0-2.08-2.4-2.11c-1.91-.03-2.26,1.84-2.75,1.91s-1.52.54-2.44.47-2.02-.43-3.07-.99c-.42-.22-.7-1.38-2.74-1.38s-2.89,1.76-3.32,1.91-1.34.49-2.47.42-2.33-.28-2.82-1.13S16.16.06,14.68.06s-1.91-.1-2.54-.03-.78.05-1.48.53c-.5.34-.3.88-1.2,1.62-.78.64-3.86.43-4.02,1.62-.14,1.06.42,1.34,1.76,1.34s1.34,1.48,2.95,1.88c1.17.29,1.78.03,4.32.1s3.04-1.2,4.8-1.2,2.12,2.26.64,2.33c-2.33.11-1.64.79-2.54,1.55s-1.91.64-2.82.56-1.69.28-2.54-.35-1-.89-2.21-1.4c-.98-.41-1.32-.18-2.87-.11s-1.89.94-2.46,1.44C3.81,10.5,0,9.94,0,11.55c0,1.49,1.06,1.56,1.91,1.63s1.55.07,2.33.21,1.84.21,2.19.78.85,1.27,2.54,1.2Z',
    viewBox: '0 0 64.36 23.07'
  },
  {
    name: 'cloud-middle',
    pathDrawn: 'm7.76,21.88c-.1.56,1.48,1.27,3.25,1.27s3.92-.56,5.51-.56c3.04,0,2.96,1.62,1.84,1.62s-1.48.42-1.48.85,1.91.56,3.18.56,3.46-.09,3.46-1.44c0-1.6,1.4-1.6,2.26-1.6,1.41,0,1.58-1.32,2.92-1.32s1.76.57,1.76,1.09c0,1.06,1.74,2.42,4.29,2.42s2.32,1.41,4.37,1.41c1.69,0,2.03-.72,2.74-.72,1.06,0,1.99-1.32,3.26-1.32,1.35,0,6.99.85,6.99-.78,0-1.84-2.54-1.34-4.09-1.34s-1.34-1.92-3.95-1.84-2.05-2.89.92-2.82,3.67.92,5.93.92c1.2,0,4.09-2.19,5.29-2.19,2.19,0,2.89.8,4.09.8s2.26-1.65,3.82-1.65,2.03.92,2.68.92c4.09,0,2.82-2.66.76-2.66-1.15,0-1.54-1.43-3.3-1.43s-1.62,1.43-2.82,1.43c-1.41,0-1.41-.78-3.04-.86s-2.88.86-4.09.86c-1.91,0-.21-1.48-3.76-1.48-.92,0-1.39-1.01-3.79-1.01s-3.68,1.34-5.79,1.41-.58-1.27-2.56-1.27c-1.13,0-1.04-2.11.58-2.11,1.88,0,1.44-.99,2.71-1.06s1.6-2.05,3.01-1.98,2.05.49,2.05,1.76c0,1.41,2.75.78,4.09.71s4.09-.92,5.22-.92,2.19.64,3.25.56,2.69-.85,2.61-1.62c-.07-.71-.99-1.98-3.67-1.98s-2.82.71-4.38.56-4.66-.42-4.31-1.2-.35-1.55-1.76-1.55-2.54,0-3.25-.78S42.71,0,40.73,0s-3.53,1.66-4.87,1.69C33.42,1.77,34.1.02,30.35,0c-3.11-.02-3.46,1.76-4.87,1.84s-1.91,1.2-3.18,1.2-4.51-.28-5.36-.28c-3.65,0-3.55,2.47-.49,2.4,3.25-.07.64,1.84,6.07,1.69,1.91-.05,1.69,2.01.28,1.98-2.68-.07-1.98,2.12-3.81,2.12s-3.11.35-3.67.07-1.2-1.76-2.75-1.76-2.4.28-2.68.56-1.35,1.54-2.26,1.54-2.47.23-3.88.15S0,11.58,0,12.81c0,1.52,2.4,1.98,3.81,1.95,3.25-.07,5.36-1.95,6.99-1.95,3.6,0,2.54,3.92,5.22,3.92,2.4,0,1.64-1.51,3.67-1.76,1.13-.14,2.35.86,3.95,1.06,1.82.23,3.49.07,3.46,1.34-.04,1.36-3.81,1.06-5.08,1.06-.58,0-2.99-.86-2.99.4,0,1.09-2.17.31-3.01.31-1.7,0-3.25,1.2-4.45,1.13s-3.6.49-3.81,1.62Z',
    viewBox: '0 0 69.47 26.19'
  },
  {
    name: 'cloud-bottom',
    pathDrawn: 'm40.38,40.49c1.6,0,3.39-2.09,5.51-2.09s2.12-2.12,3.67-2.12,6.08-1.19,6.08-3.02c0-1.63-3.82-1.07-5.79-1.07s-1.94-.22-2.56-.85-1.39-1.84-2.66-1.84-2.54.28-2.54-.71,1.55-1.27,2.96-1.27.85-2.4,3.67-2.4c2.35,0,2.17,1.78,3.86,1.78s2.21,1.93,4.75,1.93,3.11-1.57,4.67-1.57,1.69-2.24,4.66-2.24c2.53,0,.56,2.5,2.96,2.5s1.27-1.84,3.39-1.84,6.78-.85,6.78-2.12-2.84-1.28-4.74-1.28-3.03-1.63-5.15-1.63-3.95,1.78-6.35,1.78c-2.12,0-2.87-2.12-4.4-2.12s-2.44-.32-2.44-1.45,1.01-.95,2.44-.95.44-2.54,2.99-2.54c2.96,0,2.67,1.15,4.44,1.15,3.33,0,3.17-1.72,5.14-1.72s6.94-.56,6.94-1.69-2.13-1.76-4.67-1.76-4.08-.08-5.49-.08c-2.4,0-.28-2.23-5.14-2.23-2.91,0-2.41,3.12-4.36,3.12s-1.22-2.4-4.4-2.4-2.43-2.3-.59-2.3c4.14,0,2.92.34,4.89.28,3.39-.09,1.98-2.68,4.94-2.68,3.53,0,4.47-3.25-.28-3.25-1.13,0-2.4-1.84-4.24-1.84-2.12,0-1.98,2.26-3.25,2.26s-.85,1.51-3.98,1.51c-2.37,0-1.69-2.92-5.19-2.92-3.14,0-6.09,2.31-8.42,2.31s-3.29-1.6-5.55-1.6c-1.98,0-1.67,1.69-3.67,1.69s-4.52.62-4.52,1.41c0,1.84,4.72,2.66,7.83,2.66,2.06,0,1.66-.4,3.56-.4,1.55,0,1.84,1.06,3.01,1.06,1.04,0,2.05.66,2.05,1.2,0,1.07-.78,1.55-2.33,1.55-1.41,0-1.31,1.27-3.11,1.27-4.52,0-2.4-.85-5.22-.85s-1.65,1.87-3.91,1.87-1.74-1.31-4.85-1.31c-1.99,0-2.62,1.13-4.54,1.13-2.02,0-3.51.99-3.51,2.12s4.06,1.9,5.75,1.9,3.14-1.19,5.12-1.19,1.59,2.23,3.71,2.23,1.21-1.53,3.47-1.53,1.99,1.23,4.26,1.23c2.82,0,2.68,3.15-.14,3.15s.25,1.31-4.21,1.31c-3.1,0-1.01,2.35-5.25,2.35-1.98,0-1.1-1.26-3.51-1.26-1.84,0-.77-1.14-3.55-1.14-3.86,0-4.38,3.67-7.91,3.67-4.09,0-4.31-2.12-6.49-2.12s-4.94.56-4.94,2.4,1.97,2.32,3.39,2.26c3.25-.14,1.41,2.4,3.95,2.4,2.68,0,4.38-2.26,6.49-2.26,3.95,0,1.84,1.41,6.07,1.41s3.79-1.25,7.16-1.25c1.94,0,2.3,1.1,4.15,1.1,2.06,0,3.45.3,3.57,1.37.19,1.84-.89,2.38-3.59,2.38-2.03,0-1.12-.92-4.5-.92-4.66,0-7.76,1.18-7.76,2.38,0,1.13,3.57,1.43,6.48,1.43,3.39,0,.99,3.25,4.09,3.25s1.98-2.26,6.07-2.26c3.11,0,2.08,3.22,4.8,3.22Z',
    viewBox: '0 0 79.76 40.49'
  }
];

/* Waxing Crescent Phase Paths */
const waxingCrescentEightSteps: { [key: string]: string } = {
  xc81: 'm20.7,4.19c1.86,2.07,2.99,4.81,2.99,7.81s-1.13,5.74-2.99,7.81c1.43-2.26,2.26-4.94,2.26-7.81s-.83-5.55-2.26-7.81Z',
  xc82: 'm18.86,2.52c2.93,2.13,4.84,5.58,4.84,9.48s-1.91,7.35-4.84,9.48c2.11-2.58,3.38-5.88,3.38-9.48s-1.27-6.89-3.38-9.48Z',
  xc83: 'm17.52,1.69c3.67,1.97,6.17,5.85,6.17,10.31s-2.5,8.34-6.17,10.31c2.47-2.73,3.98-6.34,3.98-10.31s-1.51-7.59-3.98-10.31Z',
  xc84: 'm16.47,1.19c4.24,1.76,7.22,5.94,7.22,10.81s-2.98,9.05-7.22,10.81c2.57-2.86,4.12-6.65,4.12-10.81s-1.55-7.96-4.12-10.81Z',
  xc85: 'm15.32.78c4.84,1.43,8.37,5.91,8.37,11.22s-3.53,9.79-8.37,11.22c2.6-3.01,4.17-6.93,4.17-11.22S17.92,3.79,15.32.78Z',
  xc86: 'm14.16.5c5.43,1.01,9.54,5.78,9.54,11.5,0,5.72-4.11,10.49-9.54,11.5,2.43-3.19,3.87-7.18,3.87-11.5S16.59,3.69,14.16.5Z',
  xc87: 'm13.1.35c5.94.56,10.6,5.56,10.6,11.65,0,6.09-4.65,11.09-10.6,11.65,1.97-3.43,3.1-7.41,3.1-11.65S15.07,3.78,13.1.35Z',
  xc88: 'm12.19.3c6.37.11,11.5,5.3,11.5,11.7,0,6.4-5.13,11.59-11.5,11.7,1.29-3.66,2-7.6,2-11.7s-.7-8.04-2-11.7Z'
};

const waxingCrescentSevenSteps: { [key: string]: string } = {
  xc71: waxingCrescentEightSteps.xc81,
  xc72: 'm18.42,2.22c3.18,2.09,5.27,5.69,5.27,9.78s-2.1,7.69-5.27,9.78c2.26-2.62,3.63-6.04,3.63-9.78s-1.37-7.15-3.63-9.78Z',
  xc73: 'm17.07,1.46c3.92,1.89,6.62,5.9,6.62,10.54s-2.7,8.65-6.62,10.54c2.42-2.82,3.88-6.51,3.88-10.54s-1.46-7.72-3.88-10.54Z',
  xc74: 'm15.57.86c4.71,1.51,8.13,5.93,8.13,11.14s-3.41,9.63-8.13,11.14c2.56-3,4.1-6.89,4.1-11.14S18.13,3.86,15.57.86Z',
  xc75: waxingCrescentEightSteps.xc86,
  xc76: waxingCrescentEightSteps.xc87,
  xc77: waxingCrescentEightSteps.xc88
};

const waxingCrescentSixSteps: { [key: string]: string } = {
  xc61: waxingCrescentEightSteps.xc81,
  xc62: 'm18.14,2.04c3.33,2.06,5.56,5.75,5.56,9.96s-2.22,7.9-5.56,9.96c2.33-2.65,3.73-6.14,3.73-9.96s-1.4-7.31-3.73-9.96Z',
  xc63: 'm16.18,1.07c4.39,1.68,7.51,5.94,7.51,10.93s-3.12,9.24-7.51,10.93c2.63-2.87,4.22-6.71,4.22-10.93s-1.59-8.06-4.22-10.93Z',
  xc64: 'm14.53.58c5.24,1.16,9.16,5.83,9.16,11.42s-3.92,10.26-9.16,11.42c2.63-3.07,4.23-7.06,4.23-11.42S17.17,3.65,14.53.58Z',
  xc65: 'm13.31.37c5.84.65,10.38,5.61,10.38,11.63,0,6.02-4.54,10.97-10.38,11.63,2.28-3.3,3.62-7.31,3.62-11.63S15.59,3.68,13.31.37Z',
  xc66: 'm12.56.31c6.2.3,11.13,5.41,11.13,11.69,0,6.27-4.94,11.39-11.13,11.69,1.29-3.66,1.99-7.59,1.99-11.69s-.7-8.03-1.99-11.69Z'
};

const waxingCrescentFiveSteps: { [key: string]: string } = {
  xc51: waxingCrescentEightSteps.xc81,
  xc52: waxingCrescentEightSteps.xc83,
  xc53: waxingCrescentSevenSteps.xc74,
  xc54: 'm13.92.46c5.55.92,9.78,5.73,9.78,11.54,0,5.81-4.23,10.62-9.78,11.54,2.25-3.29,3.56-7.26,3.56-11.54S16.17,3.74,13.92.46Z',
  xc55: 'm12.62.32c6.17.32,11.08,5.43,11.08,11.68,0,6.25-4.9,11.36-11.08,11.68,1.48-3.6,2.3-7.55,2.3-11.68S14.1,3.92,12.62.32Z'
};

/* Waxing Gibbous Phase Paths */
const waxingGibbousEightSteps: { [key: string]: string } = {
  xg81: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-.07,0-.13,0-.2,0-1.29,3.66-2,7.6-2,11.7s.7,8.04,2,11.7c.07,0,.13,0,.2,0,6.46,0,11.7-5.24,11.7-11.7Z',
  xg82: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-.37,0-.74.02-1.1.05-1.97,3.43-3.1,7.41-3.1,11.65s1.13,8.22,3.1,11.65c.36.03.73.05,1.1.05,6.46,0,11.7-5.24,11.7-11.7Z',
  xg83: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-.74,0-1.46.07-2.16.2-2.43,3.19-3.87,7.18-3.87,11.5s1.44,8.31,3.87,11.5c.7.13,1.42.2,2.16.2,6.46,0,11.7-5.24,11.7-11.7Z',
  xg84: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-1.16,0-2.27.17-3.33.48-2.6,3.01-4.17,6.93-4.17,11.22s1.57,8.21,4.17,11.22c1.05.31,2.17.48,3.33.48,6.46,0,11.7-5.24,11.7-11.7Z',
  xg85: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-1.59,0-3.1.32-4.48.89-2.57,2.86-4.12,6.65-4.12,10.81s1.55,7.96,4.12,10.81c1.38.57,2.89.89,4.48.89,6.46,0,11.7-5.24,11.7-11.7Z',
  xg86: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-2,0-3.88.5-5.53,1.39-2.47,2.73-3.98,6.34-3.98,10.31s1.51,7.59,3.98,10.31c1.65.88,3.53,1.39,5.53,1.39,6.46,0,11.7-5.24,11.7-11.7Z',
  xg87: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-2.56,0-4.93.83-6.86,2.22-2.11,2.58-3.38,5.88-3.38,9.48s1.27,6.89,3.38,9.48c1.93,1.4,4.3,2.22,6.86,2.22,6.46,0,11.7-5.24,11.7-11.7Z',
  xg88: 'm12,23.7c6.46,0,11.7-5.24,11.7-11.7S18.46.3,12,.3c-3.46,0-6.57,1.5-8.71,3.89-1.43,2.26-2.26,4.94-2.26,7.81s.83,5.55,2.26,7.81c2.14,2.39,5.25,3.89,8.71,3.89Z'
};

const waxingGibbousSevenSteps: { [key: string]: string } = {
  xg71: waxingGibbousEightSteps.xg81,
  xg72: waxingGibbousEightSteps.xg82,
  xg73: waxingGibbousEightSteps.xg83,
  xg74: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-1.25,0-2.45.2-3.57.56-2.56,3-4.1,6.89-4.1,11.14s1.55,8.14,4.1,11.14c1.13.36,2.33.56,3.57.56,6.46,0,11.7-5.24,11.7-11.7Z',
  xg75: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-1.82,0-3.54.42-5.08,1.16-2.42,2.82-3.88,6.51-3.88,10.54s1.46,7.72,3.88,10.54c1.54.74,3.26,1.16,5.08,1.16,6.46,0,11.7-5.24,11.7-11.7Z',
  xg76: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-2.37,0-4.58.71-6.43,1.92-2.26,2.62-3.63,6.04-3.63,9.78s1.37,7.15,3.63,9.78c1.84,1.22,4.05,1.92,6.43,1.92,6.46,0,11.7-5.24,11.7-11.7Z',
  xg77: waxingGibbousEightSteps.xg88
};

const waxingGibbousSixSteps: { [key: string]: string } = {
  xg61: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-.19,0-.38,0-.57.01-1.29,3.66-1.99,7.59-1.99,11.69s.7,8.03,1.99,11.69c.19,0,.38.01.57.01,6.46,0,11.7-5.24,11.7-11.7Z',
  xg62: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-.44,0-.88.03-1.32.07-2.28,3.3-3.62,7.31-3.62,11.63s1.34,8.32,3.62,11.63c.43.05.87.07,1.32.07,6.46,0,11.7-5.24,11.7-11.7Z',
  xg63: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-.87,0-1.72.1-2.54.28-2.63,3.07-4.23,7.06-4.23,11.42s1.59,8.35,4.23,11.42c.82.18,1.67.28,2.54.28,6.46,0,11.7-5.24,11.7-11.7Z',
  xg64: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-1.48,0-2.89.27-4.19.77C5.18,3.94,3.59,7.78,3.59,12s1.59,8.06,4.22,10.93c1.3.5,2.71.77,4.19.77,6.46,0,11.7-5.24,11.7-11.7Z',
  xg65: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-2.25,0-4.36.64-6.14,1.74-2.33,2.65-3.73,6.14-3.73,9.96s1.4,7.31,3.73,9.96c1.79,1.1,3.89,1.74,6.14,1.74,6.46,0,11.7-5.24,11.7-11.7Z',
  xg66: waxingGibbousEightSteps.xg88
};

const waxingGibbousFiveSteps: { [key: string]: string } = {
  xg51: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-.21,0-.42,0-.62.02-1.48,3.6-2.3,7.55-2.3,11.68s.82,8.08,2.3,11.68c.21.01.41.02.62.02,6.46,0,11.7-5.24,11.7-11.7Z',
  xg52: 'm23.7,12C23.7,5.54,18.46.3,12,.3c-.65,0-1.3.05-1.92.16-2.25,3.29-3.56,7.26-3.56,11.54s1.32,8.26,3.56,11.54c.63.1,1.27.16,1.92.16,6.46,0,11.7-5.24,11.7-11.7Z',
  xg53: waxingGibbousSevenSteps.xg74,
  xg54: waxingGibbousEightSteps.xg86,
  xg55: waxingGibbousEightSteps.xg88
};

const lunarPhasesSVGPaths: LunarPhases = {

  // principal phase paths
  newMoon: 'm12,.3C5.54.3.3,5.54.3,12s5.24,11.7,11.7,11.7,11.7-5.24,11.7-11.7S18.46.3,12,.3Zm0,23.12C5.69,23.42.58,18.31.58,12S5.69.58,12,.58s11.42,5.11,11.42,11.42-5.11,11.42-11.42,11.42Z',
  firstQuarterMoon: 'm12,.3v23.4c6.46,0,11.7-5.24,11.7-11.7S18.46.3,12,.3Z',
  fullMoon: 'M12,12m-11.7,0a11.7,11.7 0 1 0 23.4,0a11.7,11.7 0 1 0 -23.4,0',
  thirdQuarterMoon: 'm12,.3c0,5.84,0,17.04,0,23.4-6.46,0-11.7-5.24-11.7-11.7,0-6.46,5.24-11.7,11.7-11.7Z',

  // waxing crescent phase paths
  xc81: waxingCrescentEightSteps.xc81,
  xc82: waxingCrescentEightSteps.xc82,
  xc83: waxingCrescentEightSteps.xc83,
  xc84: waxingCrescentEightSteps.xc84,
  xc85: waxingCrescentEightSteps.xc85,
  xc86: waxingCrescentEightSteps.xc86,
  xc87: waxingCrescentEightSteps.xc87,
  xc88: waxingCrescentEightSteps.xc88,

  xc71: waxingCrescentSevenSteps.xc71,
  xc72: waxingCrescentSevenSteps.xc72,
  xc73: waxingCrescentSevenSteps.xc73,
  xc74: waxingCrescentSevenSteps.xc74,
  xc75: waxingCrescentSevenSteps.xc75,
  xc76: waxingCrescentSevenSteps.xc76,
  xc77: waxingCrescentSevenSteps.xc77,

  xc61: waxingCrescentSixSteps.xc61,
  xc62: waxingCrescentSixSteps.xc62,
  xc63: waxingCrescentSixSteps.xc63,
  xc64: waxingCrescentSixSteps.xc64,
  xc65: waxingCrescentSixSteps.xc65,
  xc66: waxingCrescentSixSteps.xc66,

  xc51: waxingCrescentFiveSteps.xc51,
  xc52: waxingCrescentFiveSteps.xc52,
  xc53: waxingCrescentFiveSteps.xc53,
  xc54: waxingCrescentFiveSteps.xc54,
  xc55: waxingCrescentFiveSteps.xc55,

  // waxing gibbous phase paths
  xg81: waxingGibbousEightSteps.xg81,
  xg82: waxingGibbousEightSteps.xg82,
  xg83: waxingGibbousEightSteps.xg83,
  xg84: waxingGibbousEightSteps.xg84,
  xg85: waxingGibbousEightSteps.xg85,
  xg86: waxingGibbousEightSteps.xg86,
  xg87: waxingGibbousEightSteps.xg87,
  xg88: waxingGibbousEightSteps.xg88,

  xg71: waxingGibbousSevenSteps.xg71,
  xg72: waxingGibbousSevenSteps.xg72,
  xg73: waxingGibbousSevenSteps.xg73,
  xg74: waxingGibbousSevenSteps.xg74,
  xg75: waxingGibbousSevenSteps.xg75,
  xg76: waxingGibbousSevenSteps.xg76,
  xg77: waxingGibbousSevenSteps.xg77,

  xg61: waxingGibbousSixSteps.xg61,
  xg62: waxingGibbousSixSteps.xg62,
  xg63: waxingGibbousSixSteps.xg63,
  xg64: waxingGibbousSixSteps.xg64,
  xg65: waxingGibbousSixSteps.xg65,
  xg66: waxingGibbousSixSteps.xg66,

  xg51: waxingGibbousFiveSteps.xg51,
  xg52: waxingGibbousFiveSteps.xg52,
  xg53: waxingGibbousFiveSteps.xg53,
  xg54: waxingGibbousFiveSteps.xg54,
  xg55: waxingGibbousFiveSteps.xg55,

  // waning gibbous phase paths (these are waxing paths to be flipped horizontally on render)
  ng81: waxingGibbousEightSteps.xg88,
  ng82: waxingGibbousEightSteps.xg87,
  ng83: waxingGibbousEightSteps.xg86,
  ng84: waxingGibbousEightSteps.xg85,
  ng85: waxingGibbousEightSteps.xg84,
  ng86: waxingGibbousEightSteps.xg83,
  ng87: waxingGibbousEightSteps.xg82,
  ng88: waxingGibbousEightSteps.xg81,

  ng71: waxingGibbousSevenSteps.xg77,
  ng72: waxingGibbousSevenSteps.xg76,
  ng73: waxingGibbousSevenSteps.xg75,
  ng74: waxingGibbousSevenSteps.xg74,
  ng75: waxingGibbousSevenSteps.xg73,
  ng76: waxingGibbousSevenSteps.xg72,
  ng77: waxingGibbousSevenSteps.xg71,

  ng61: waxingGibbousSixSteps.xg66,
  ng62: waxingGibbousSixSteps.xg65,
  ng63: waxingGibbousSixSteps.xg64,
  ng64: waxingGibbousSixSteps.xg63,
  ng65: waxingGibbousSixSteps.xg62,
  ng66: waxingGibbousSixSteps.xg61,

  ng51: waxingGibbousFiveSteps.xg55,
  ng52: waxingGibbousFiveSteps.xg54,
  ng53: waxingGibbousFiveSteps.xg53,
  ng54: waxingGibbousFiveSteps.xg52,
  ng55: waxingGibbousFiveSteps.xg51,

  // waning crescent phase paths (these are waxing paths to be flipped horizontally on render)
  nc81: waxingCrescentEightSteps.xc88,
  nc82: waxingCrescentEightSteps.xc87,
  nc83: waxingCrescentEightSteps.xc86,
  nc84: waxingCrescentEightSteps.xc85,
  nc85: waxingCrescentEightSteps.xc84,
  nc86: waxingCrescentEightSteps.xc83,
  nc87: waxingCrescentEightSteps.xc82,
  nc88: waxingCrescentEightSteps.xc81,

  nc71: waxingCrescentSevenSteps.xc77,
  nc72: waxingCrescentSevenSteps.xc76,
  nc73: waxingCrescentSevenSteps.xc75,
  nc74: waxingCrescentSevenSteps.xc74,
  nc75: waxingCrescentSevenSteps.xc73,
  nc76: waxingCrescentSevenSteps.xc72,
  nc77: waxingCrescentSevenSteps.xc71,

  nc61: waxingCrescentSixSteps.xc66,
  nc62: waxingCrescentSixSteps.xc65,
  nc63: waxingCrescentSixSteps.xc64,
  nc64: waxingCrescentSixSteps.xc63,
  nc65: waxingCrescentSixSteps.xc62,
  nc66: waxingCrescentSixSteps.xc61,

  nc51: waxingCrescentFiveSteps.xc55,
  nc52: waxingCrescentFiveSteps.xc54,
  nc53: waxingCrescentFiveSteps.xc53,
  nc54: waxingCrescentFiveSteps.xc52,
  nc55: waxingCrescentFiveSteps.xc51
};

const svgPaths = {
  arrowDirectional,
  closingX,
  moonClouds,
  lunarPhasesSVGPaths
};

export default svgPaths;
